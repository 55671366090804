import React from 'react'
import styled from 'styled-components'
import theme from '../theme'

interface Props {
  id?: string
  number?: string
  color?: string
  text?: string
}

export function NumberHighlight(props: Props) {
  const { id, number, color, text } = props
  return (
    <Container {...props}>
      <div className="number-container">
        <h1 className="number">
          <div className={color}>{number}</div>
        </h1>
        <h2 id={id}>{text}</h2>
      </div>
    </Container>
  )
}

export default NumberHighlight

const Container = styled.div<Props>`
  //trick to make anchor links point to the right place
  &:before {
    content: '';
    display: block;
    height: 120px;
    margin: -120px 0 0;
  }

  .number div {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    text-align: center;
    padding-top: 25px;
    background: ${props =>
      props.color ? theme.colors[props.color] : theme.colors.dataPurple};
    color: white;
    font-size: 2.5rem;
  }
  h2 {
    margin-top: 1rem;
  }

  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    .number-container {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-bottom: 2rem;

      h2 {
        margin-left: 2rem;
        margin-right: 10rem;
      }
    }
  }
`
