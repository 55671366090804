import React from 'react'
import styled from 'styled-components'
import Block from '../../components/Block'
import CTASection from '../../components/CTASection'
import { Hero } from '../../components/Hero'
import Layout from '../../components/Layout'
import NumberHighlight from '../../components/NumberHighlight'
import Page from '../../components/Page'
import { SwarmiaBackgroundPattern } from '../../components/SwarmiaBackgroundPattern'
import theme from '../../theme'

const ObjectionsPage = () => {
  return (
    <Layout
      title="Objections"
      description="Engineering teams are getting results with Swarmia. But every team is different. Will it work for you? Here are some real objections we've heard."
    >
      <ObjectionsStyles>
        <Hero background={<SwarmiaBackgroundPattern />}>
          <Block type="padding-medium">
            <h1>Is Swarmia the right tool&nbsp;for you?</h1>
            <p className="text-large">
              Engineering teams are getting results with Swarmia. But every team
              is different. Will it work for you? Here are some real objections
              we&#39;ve heard.
            </p>
          </Block>
        </Hero>
        <Page>
          <Block type="padding-small">
            <h6>COMMON OBJECTIONS</h6>
            <div className="objections-links">
              <ol className="objections-links-column-one">
                <li>
                  <a href="#one">I don&#39;t understand who Swarmia is for</a>
                </li>
                <li>
                  <a href="#two">
                    Our teams are not ready for this kind of transparency
                  </a>
                </li>
                <li>
                  <a href="#three">
                    I don&#39;t need yet another dashboard to look at
                  </a>
                </li>
                <li>
                  <a href="#four">
                    I don&#39;t have time for this. We have more pressing issues
                    to solve
                  </a>
                </li>
                <li>
                  <a href="#five">
                    I don&#39;t think a tool can accurately reflect the way we
                    work
                  </a>
                </li>
              </ol>
              <ol className="objections-links-column-two">
                <li>
                  <a href="#six">
                    I don&#39;t think I&#39;m going to learn anything new from
                    this tool
                  </a>
                </li>
                <li>
                  <a href="#seven">
                    I can write scripts to get the same data myself
                  </a>
                </li>
                <li>
                  <a href="#eight">
                    Onboarding to this new tool sounds like a lot of work
                  </a>
                </li>
                <li>
                  <a href="#nine">
                    I don&#39;t want to introduce yet another UI to my
                    developers
                  </a>
                </li>
                <li>
                  <a href="#ten">
                    All metrics are flawed anyway, why should I care?
                  </a>
                </li>
              </ol>
            </div>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="1"
              color="dataPurple"
              id="one"
              text="I don't understand who Swarmia is for"
            />
            <p>
              We believe that{' '}
              <a href="/blog/measuring-software-development-productivity/">
                long-term engineering productivity improvements
              </a>{' '}
              start from the needs of engineering teams. If you don&apos;t get
              the teams&apos; buy-in when measuring productivity and engagement,
              it&apos;s unlikely that you would be able to trust the data for
              further insights.
            </p>
            <p>
              We built Swarmia by focusing on teams, giving them{' '}
              <a href="/product/visibility/">visibility</a> into how they work
              and how they can improve. Here&apos;s how it works:
            </p>
            <ul>
              <li>Teams get actionable insights to improve every day.</li>
              <li>
                They own their data, ensuring the best possible data quality.
              </li>
              <li>Engineering leaders get high-level visibility.</li>
              <li>
                Individual developers get context to their daily work and{' '}
                <a href="/developers/">handy tools that save them time</a>.
              </li>
            </ul>
            <p>
              A holistic view of engineering productivity, combined with our
              focus on teams leads to long-term productivity improvements for
              the whole organization.
            </p>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="2"
              color="dataGreen"
              id="two"
              text="Our teams are not ready for this kind of transparency"
            />
            <p>
              The software industry has a long history of focusing on harmful
              metrics like lines of code to measure developer productivity. But
              there is a pragmatic way to increase transparency in a way that
              actually helps teams{' '}
              <a href="/blog/issue-cycle-time/">ship better software faster</a>.
              Rather than spying on developers, we help you find the real
              bottlenecks in your organization.{' '}
              <strong>
                These bottlenecks are almost invariably processes, not people.
              </strong>
            </p>
            <p>
              We know that the best teams focus on optimizing for the whole, not
              the individual. In Swarmia, you won&apos;t see leaderboards
              ranking developers or teams from best to worst. We focus on{' '}
              <a href="/blog/measuring-software-development-productivity/">
                healthy, well-researched team metrics
              </a>{' '}
              around focus, delivery, collaboration, and quality &mdash; areas
              that are already in the teams&apos; domain. With added visibility
              to teams&rsquo; focus, delivery, collaboration, and quality, we
              can focus on gradually improving the teams&apos; ways of working.
            </p>
            <p>
              If someone in the team is blocked or ends up working alone on a
              complex problem, it&apos;s a team matter too. Swarmia enables{' '}
              <a href="/blog/data-driven-retrospectives-stop-fake-improvements/">
                meaningful conversations in 1-1&apos;s and team meetings
              </a>{' '}
              based on real data.
            </p>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="3"
              color="dataCoffee"
              id="three"
              text="I don't need yet another dashboard to look at"
            />
            <p>
              We believe in the power of metrics, but metrics alone don&apos;t
              drive change. Our goal is to help teams improve in a way that
              sticks. It means not only identifying blockers and anti-patterns
              but helping teams to take action to improve every day.
            </p>
            <p>
              In Swarmia, teams create{' '}
              <a href="/product/working-agreements/">working agreements</a>{' '}
              around how they want to work. Here are some examples:
            </p>
            <ul>
              <li>Merge all pull requests in under 7 days</li>
              <li>Review pull requests in under 24 hours</li>
              <li>Don&apos;t push code to master without review</li>
              <li>
                Don&apos;t work on more than two user stories at once (work in
                progress limits)
              </li>
              <li>Resolve critical bugs in under 24 hours</li>
            </ul>
            <p>
              But building new habits doesn&apos;t happen automatically. People
              often need to be reminded of new ways of working, and doing this
              constantly as a leader is an thankless job. Our Slack bot
              carefully nudges teams to respect their agreements and take action
              to stay on track. It may mean closing those stale pull requests,
              prioritizing a project lagging behind schedule due to unplanned
              work, or unblocking a person working alone on a complex problem.
            </p>
            <p>
              This approach has enabled several of our customers to start
              shipping code 30&ndash;50% faster in under 3 months.
            </p>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="4"
              color="dataPink"
              id="four"
              text="I don't have time for this. We have more pressing issues to solve."
            />
            <p>
              Sure, when your organization is already dealing with a lot of
              complexity, starting with a new tool doesn&apos;t sound like a
              brilliant idea. Your focus is on delivering on those business
              objectives, scaling the team, and keeping the organization
              running.
            </p>
            <p>
              Imagine you could remove blockers slowing teams down, and deliver
              on your top priorities 10x faster. For an engineering leader, is
              there anything more important?
            </p>
            <p>
              There are no silver bullets to 10x improvement, and driving
              systematic change requires time, commitment, and outstanding
              leadership. Engineering productivity has to be on top of a
              leader&apos;s agenda. The good news? You can start improving
              today.
            </p>
            <p>
              Swarmia creates and fosters a culture of continuous improvement
              within your teams and boosts focus and delivery without steering
              their attention away from things that matter. We&apos;ve seen
              teams achieving a sharp productivity increase with Swarmia within
              4&ndash;6 weeks.
            </p>
            <p>
              If you&apos;re still thinking now is not the right time to start
              improving productivity,{' '}
              <a href="/blog/how-to-make-time-for-improving-software-development-productivity/">
                check out this blog post
              </a>
              .
            </p>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="5"
              color="dataLightblue"
              id="five"
              text="I don't think this tool can accurately reflect the way we
              work"
            />
            <p>
              If there&apos;s one thing we know about modern engineering
              organizations, it&apos;s that every team is different. We
              don&apos;t push you into a One Right Way of Working&trade;️, but
              adapt to your process and learn from how you work.
            </p>
            <p>
              If your teams use Jira in a unique way, that&apos;s not a problem.
              We have never seen two teams using Jira the same way, really. If
              teams have different routines or interpretations of kanban, scrum,
              sprints, retrospectives, etc. we adapt to that.
            </p>
            <p>
              We support a vast array of team structures and hierarchies,
              squads, groups, tribes etc. In the unlikely case we don&apos;t
              already support the way you working, we&apos;re happy to learn
              more and adapt.
            </p>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="6"
              color="dataRed"
              id="six"
              text="I don't think I'm going to learn anything new from this tool"
            />
            <p>
              Many blockers that slow teams down are quite tricky to diagnose
              with your existing tools. In reality,{' '}
              <a href="/blog/issue-cycle-time/">the most common blockers</a>{' '}
              take no longer than a week to remove, but if you&apos;re oblivious
              to their existence, they will keep haunting teams for years.
            </p>
            <p>
              Most of our customers are surprised to see just how much unplanned
              work is not reflected on their roadmap, but is now visible in
              Swarmia.
            </p>
            <p>
              Will this be the case with you? We don&apos;t know. After your
              issue tracker data has synced, Swarmia takes 10 minutes to set up.
              If you don&apos;t find value in the tool within your 14&ndash;day
              trial, you can simply disconnect your source code hosting and
              issue tracker.
            </p>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="7"
              color="dataBlue"
              id="seven"
              text="I can write scripts to get the same data myself"
            />
            <p>
              We&apos;ve been there too: writing scripts for Jira and GitHub and
              building Slack bots as a band-aid to the lack of visibility and
              insights. Many of our customers have tried writing scripts, too,
              before realizing how difficult it is to maintain and get data you
              can actually trust.
            </p>
            <p>
              Our team focus enables the industry&apos;s best data quality
              because teams in Swarmia own their own data. Here are some things
              we&apos;ve considered around data quality:
            </p>
            <ul>
              <li>Flexible team-specific Jira and GitHub configuration</li>
              <li>
                Automated assignment of contributions to the right people and
                projects
              </li>
              <li>
                Contributor management, and handling comparisons vs. historical
                data
              </li>
              <li>Filtering out noise and outliers in the data</li>
            </ul>
            <p>
              When it comes to data quality,{' '}
              <a href="/blog/issue-cycle-time/">cycle time</a> has a special
              place in our hearts. There are myriad corner cases to consider. We
              spent long hours discussing it, we&apos;ve even named a room in
              our office the &quot;Cycle Time Lounge&quot; (we also have a bike
              club with the same name 🚴&zwj;♂️ ). Here are just some points
              we&apos;ve covered:
            </p>
            <ul>
              <li>
                If a pull request was merged without approval, what should the
                review time be?
              </li>
              <li>
                What if an issue was completed, but then moved back to the
                board?
              </li>
              <li>
                If one person works for multiple teams, how should contributions
                be assigned?
              </li>
            </ul>
            <p>
              We&apos;ve been successful at replacing all of our customers&apos;
              scripts with one tool. Contact us to see if the workflow you have
              in mind is covered.
            </p>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="8"
              color="dataPurple"
              id="eight"
              text="Onboarding to this new tool sounds like a lot of work"
            />
            <p>
              We know the pain of bringing new tools in, and we value your time.
              So we thought of things like access control, contributor
              management, and activity mapping early on, to make sure it&apos;s
              easy to set up Swarmia, and it takes no time to maintain.
            </p>
            <ul>
              <li>
                We use GitHub for access control, so you don&apos;t need to
                manage access to yet another tool.
              </li>
              <li>
                It takes less than 10 minutes to set up Swarmia. All you have to
                do is connect your GitHub, Jira, and Slack, and you&apos;ll see
                the first insights immediately.
              </li>
              <li>
                Other team members can be onboarded to Swarmia at any time, and
                we provide you with materials to share with the team. Our
                customer success team is here to support you during the process.
                We&apos;re also happy to help introduce Swarmia to your teams.
              </li>
            </ul>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="9"
              color="dataTan"
              id="nine"
              text="I don't want to introduce yet another UI to my developers"
            />
            <p>
              It&apos;s difficult to get teams to adopt new tools, so we want to
              meet them where they are: in GitHub and Slack. Some teams already
              use GitHub&apos;s own Slack integration, but it&apos;s too noisy
              to be valuable &ndash; teams often send those notifications to a
              separate channel. We have a minimum-noise integration that simply
              shows when you need to act on something. We aim to reduce context
              switching and not just add another tool to your workflow.
            </p>
            <p>
              Most of the developers using Swarmia also use our Slack
              integration, and only visit our UI occasionally.
            </p>
          </Block>
          <Block size="medium" type="padding-medium">
            <NumberHighlight
              number="10"
              color="dataLightblue"
              id="ten"
              text="All metrics are flawed anyway, why should I care?"
            />
            <p>
              {' '}
              <a href="/engineering-metrics/">Engineering metrics</a> are a
              complex topic, and it&apos;s easy to do more harm than good.
              Swarmia will not summarize your product development in one number
              and give recommendations based on magic.
            </p>
            <p>
              Instead, you&apos;re in control of your data. We use the
              industry&apos;s best-researched metrics that include{' '}
              <a href="/blog/dora-metrics/">DORA metrics</a>, metrics informed
              by the SPACE framework, and years of research on engineering
              productivity. We clarify what&apos;s behind each number you see in
              the tool and show the actual data used in all calculations up
              until every commit and issue status change. It also makes it
              easier for us to debug our own data and build the best product for
              you.
            </p>
          </Block>
        </Page>
      </ObjectionsStyles>
      <CTASection>
        Ready to see Swarmia in action? Join&nbsp;the&nbsp;1,000+ teams using
        Swarmia today.
      </CTASection>
    </Layout>
  )
}

export default ObjectionsPage

const ObjectionsStyles = styled.div`
  h6 {
    margin-top: 1rem;
    text-align: center;
  }
  ol.objections-links-column-one {
    counter-reset: objections;
  }

  ol.objections-links-column-two {
    counter-reset: objections 5;
  }
  ol.objections-links-column-one,
  ol.objections-links-column-two {
    li {
      line-height: 1.3;
      margin: 0rem 1rem;
      list-style: none;
      counter-increment: objections;

      &:first-child {
        margin-top: 0;
      }

      a {
        display: inline-block;
        position: relative;
        padding: 0.75rem;

        &:before {
          content: counter(objections) '. ';
          text-align: right;
          position: absolute;
          top: 0;
          left: -38px;
          width: 32px;
          margin-top: 0.75rem;
          opacity: 0.5;
        }

        &:hover {
          border-radius: ${theme.radii['large']};
          background: ${theme.colors['hoverHighlight']};
        }
      }
    }
    ol.objections-links-column-two {
      counter-reset: '5';
    }
  }
  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    div.objections-links {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 24px;
    }
  }
`
